var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-system-bar',{attrs:{"app":"","color":"red","absolute":""}},[_c('span',{staticClass:"white--text"},[_c('span',{staticClass:"hidden-xs-only"},[_vm._v(" Tool is deprecated and data will be out of date. Please use "),_c('a',{attrs:{"href":"https://data.open-contracting.org/"}},[_vm._v("Official OCDS Data Registry.")]),_vm._v(" Data has been deleted.")])])]),_c('v-app-bar',{attrs:{"app":"","absolute":"","color":"white","flat":""}},[_c('v-navigation-drawer',{attrs:{"app":"","absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('v-btn',{staticClass:"title normal-case mb-4",attrs:{"text":"","href":href},on:{"click":navigate}},[_vm._v(" OCDS Downloads ")])]}}])}),_c('router-link',{attrs:{"to":"/use-the-data","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('v-btn',{attrs:{"text":"","href":href},on:{"click":navigate}},[_vm._v(" Use The Data ")])]}}])}),_c('router-link',{attrs:{"to":"/processing-pipeline","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('v-btn',{attrs:{"text":"","href":href},on:{"click":navigate}},[_vm._v(" Processing Pipeline ")])]}}])}),_c('router-link',{attrs:{"to":"/about","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('v-btn',{attrs:{"text":"","href":href},on:{"click":navigate}},[_vm._v(" About ")])]}}])})],1),_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-row',[_c('v-col',[_c('v-container',{staticClass:"py-0 fill-height"},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('v-btn',{staticClass:"title mr-10 normal-case",attrs:{"text":"","href":href},on:{"click":navigate}},[_vm._v(" OCDS Downloads ")])]}}])}),_c('router-link',{staticClass:"hidden-sm-and-down",attrs:{"to":"/use-the-data","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('v-btn',{attrs:{"text":"","href":href},on:{"click":navigate}},[_vm._v(" Use The Data ")])]}}])}),_c('router-link',{staticClass:"hidden-sm-and-down",attrs:{"to":"/processing-pipeline","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('v-btn',{attrs:{"text":"","href":href},on:{"click":navigate}},[_vm._v(" Processing Pipeline ")])]}}])}),_c('router-link',{staticClass:"hidden-sm-and-down",attrs:{"to":"/about","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('v-btn',{attrs:{"text":"","href":href},on:{"click":navigate}},[_vm._v(" About ")])]}}])}),_c('v-spacer'),_c('a',{staticClass:"d-none d-sm-flex",attrs:{"href":"https://opendataservices.coop/"}},[_c('img',{staticClass:"mr-4",attrs:{"src":require("@/assets/developed_by.png"),"width":"130px"}})])],1)],1)],1)],1),_c('v-main',{staticClass:"grey lighten-3"},[_c('v-container',[_c('router-view',{attrs:{"allData":_vm.allData,"originalData":_vm.originalData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }